import { FC, useCallback, useContext, useEffect,  useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Image from './Image';
import BlockContainer from './BlockContainer';
import BlockTaskQuiz from './BlockTask_Quiz';
import BlockTaskWordGame from './BlockTask_WordGame';
import Button from './Button';
import { LightboxItem, LightboxWrapper } from './LightboxModal';
import useFetchMedia from '../hooks/useFetchMedia';
import { AppContext } from '../contexts/AppContext';

interface BlockTaskProps {
  taskList?: any
}

const BlockContain = styled(BlockContainer)`
  display: block;
`;

const Paper = styled.div`
  width: 100%;
`;

const TaskContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
`;

const TaskWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 1vh 3vw 1vh 4vw;
  position: relative;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
  color: #fff;
  flex-grow: 1;
  flex-shrink: 3;
  min-height: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TaskHeader = styled.div`
  color: #fff;
  padding: 0 0 2vw;
  text-align: right;
  font-weight: bold;
`;

const ImageWrapper = styled.div`  
  width: 25vw;
  & div { height: 100%; }
`;

const Title = styled.span`
  font-weight: bold;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 1.2em;
`;

const HeroImage = styled(Image)`
  height: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  max-height: 450px;
`;

const PlainImage = styled.img`
  width: 25vw;
  min-height: 25vw;
  height: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  display: block;
  border-radius: 8px;
  object-fit: cover;
`;

const ResultWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TaskButton = styled(Button)`
  margin: 3vw auto 0;
  padding: 1.3vw 3vw;
  font-size: 1.2em;
`

/**
 * Render a task block
 * @returns {JSX.Element} Component template
 */
const BlockTask: FC<BlockTaskProps> = ({taskList}) => {
  const { t } = useTranslation();
  const { setPopup } = useContext(AppContext);
  const [currentTaskNumber, setCurrentTaskNumber] = useState<number>(0);
  const [currentTask, setCurrentTask] = useState<any>(null);
  const [tasksAreFinished, setTasksAreFinished] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [taskMedia] = useFetchMedia(taskList?.[currentTaskNumber]?.cover);

  // Set current task
  useEffect(() => {
    setCurrentTask(taskList?.[currentTaskNumber]);
  }, [taskList, currentTaskNumber]);

  /**
   * Close popup
   */
  const closePopup = useCallback(() => {
    setPopup(null);
    if (currentTaskNumber < taskList?.length - 1) {
      setCurrentTaskNumber(currentTaskNumber + 1);
    } else {
      setTasksAreFinished(true);
    }
  }, [currentTaskNumber, taskList?.length, setPopup]);

  /**
   * Callback to be run when a task has finished
   * @param {boolean} wasCorrect Was the task solved correctly?
   */
  const taskIsFinished = useCallback((wasCorrect: boolean, response?: string) => {
    if (wasCorrect) {
      setScore(score + 1);
    }
    setPopup({
      icon: wasCorrect ? "trophy" : "fault",
      title: wasCorrect ? t("task.correct") : t("task.wrong"),
      body: response || currentTask?.resultFeedback || undefined,
      buttons: [
        { title: (currentTaskNumber < taskList?.length - 1) ? t("task.next") : t("task.close"), onClick: closePopup }
      ]
    });
  }, [score, closePopup, setPopup, currentTask?.resultFeedback, currentTaskNumber, t, taskList?.length]);

  /**
   * Restart tasks
   */
  const restartTasks = useCallback(() => {
    setScore(0);
    setCurrentTaskNumber(0);
    setTasksAreFinished(false);
  }, []);

  if (taskList?.length === 0) { return null; }

  return (
    <>
      <BlockContain>
        <Paper>
          <TaskContainer className="showInPreview">
            {!tasksAreFinished ? (
              <>
                <ImageWrapper>
                  {taskMedia?.src ? (
                    <>
                    <LightboxWrapper>
                      <LightboxItem media={taskMedia}>
                        <div>
                          <HeroImage media={taskMedia}/>
                        </div>
                      </LightboxItem>
                    </LightboxWrapper>
                    </>
                  ) : (
                    <PlainImage src="./img/placeholder_task.png" />
                  )}
                </ImageWrapper>
                <TaskWrapper>
                  {taskList?.length > 1 && (<TaskHeader>{`${t("task.task")} ${currentTaskNumber + 1} ${t("task.of")} ${taskList?.length}`}</TaskHeader>)}
                  {currentTask?.taskType === "quiz" && (
                    <BlockTaskQuiz taskData={currentTask} completeCallback={taskIsFinished}/>
                  )}
                  {currentTask?.taskType === "wordgame" && (
                    <BlockTaskWordGame taskData={currentTask} completeCallback={taskIsFinished}/>
                  )}
                </TaskWrapper>
              </>
            ) : (
              <>
              <ImageWrapper>
                <PlainImage src="./img/placeholder_task.png" />
              </ImageWrapper>
                <TaskWrapper>
                  <ResultWrapper>
                    {(taskList?.length > 1) ? (
                      <Title>{t("task.youAnsweredCorrectOnXTasks").replace("{correctAnswers}", String(score)).replace("{totalTasks}", taskList?.length)}</Title>
                    ) : (
                      <Title>{score > 0 ? (<>{t("task.yourAnswerIsCorrect")}</>) : <>{t("task.yourAnswerIsWrong")}</>}</Title>
                    )}
                    <TaskButton onClick={restartTasks}>{t("task.retry")}</TaskButton>
                  </ResultWrapper>
                </TaskWrapper>
              </>
            )}
          </TaskContainer>
        </Paper>
      </BlockContain>
    </>
  );
}

export default BlockTask;